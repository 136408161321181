<template>
	<section>
		<h2>Applications</h2>
	</section>
</template>

<script>
export default {
	name: 'ProfileApplications',
}
</script>

<style scoped>
</style>
